// Welcome to Code in Framer
// Get Started: https://www.framer.com/developers/
import CloseLanguage from "https://framer.com/m/close-language-VfRf.js@0I3xiLcaLCIYLhiD1R5Q"

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/developers/#code-components-auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function Browser_language(props) {
    // This is a React component containing an Example component
    // - Replace <Example /> with your own code
    // - Find inspiration: https://www.framer.com/developers/

    return (
        <div id="browserredirect" style={containerStyle}>
            <label for="langdrop" style={labelLangDropStyle}>
                Select the country you are in to view local content.
            </label>

            <select name="langdrop" id="langdrop" style={langDropStyle}>
                <option value="" selected>
                    Choose
                </option>
                <option value="fr">France</option>
                <option value="se">Sweden</option>
            </select>
            <div id="browserredirectclosebutton" style={cursorTypePointer}>
                <CloseLanguage></CloseLanguage>
            </div>
        </div>
    )
}

// Styles are written in object syntax
// Learn more: https://reactjs.org/docs/dom-elements.html#style
const containerStyle = {
    height: "60px",
    width: "100vw",
    display: "none",
    "column-gap": "20px",
    padding: "0px 24px",
    "justify-content": "center",
}
const labelLangDropStyle = {
    "align-self": "center",
    "font-size": "14px",
    "font-family": "inter",
}

const cursorTypePointer = {
    "align-self": "center",
    cursor: "pointer",
}

const langDropStyle = {
    background: "#F7F7F7",
    border: "none",
    padding: "10px 20px",
    "border-radius": "5px",
    height: "38px",
    "align-self": "center",
}
